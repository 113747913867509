import { environment as baseEnvironment, baseUrls } from 'src/environments/environment.base';

const urlParams = new URLSearchParams(window.location.search);
const coreApiUrl = urlParams.get('core_api_url');
const storeApiUrl = urlParams.get('store_api_url');
const storageApiUrl = urlParams.get('storage_api_url');

const urlGetters = {
  get CORE_BASE_URL() {
    return coreApiUrl ?? baseUrls.CORE_BASE_URL;
  },
  get STORE_BASE_URL() {
    return storeApiUrl ?? baseUrls.STORE_BASE_URL;
  },
  get STORAGE_BASE_URL() {
    return storageApiUrl ?? baseUrls.STORAGE_BASE_URL;
  }
};

export const environment = {
  ...baseEnvironment,
  BLUEPRINT_URL: baseUrls.TEMPLATES_BASE_URL + '/blueprint.json',
  CORE_URL: urlGetters.CORE_BASE_URL + '/_ah/api',
  STORE_ENDPOINT_URL: urlGetters.STORE_BASE_URL + '/_ah/api',
  STORE_SERVER_URL: urlGetters.STORE_BASE_URL + '/',
  VIEWER_URL: baseUrls.VIEWER_BASE_URL,
  SHARED_SCHEDULE_URL: baseUrls.VIEWER_BASE_URL + '/?type=sharedschedule&id=SCHEDULE_ID',
  ALERTS_WS_URL: urlGetters.CORE_BASE_URL + '/alerts/cap',
  STORAGE_ENDPOINT_URL: urlGetters.STORAGE_BASE_URL + '/_ah/api',
  SCREEN_SHARING_SERVICE_URL: 'wss://' + baseUrls.SERVICES2_BASE_DOMAIN + '/screensharing/',
  MESSAGING_PRIMUS_URL: baseUrls.SERVICES_BASE_URL + '/messaging/primus?displayId=apps&machineId=1',
  OAUTH_TOKEN_PROVIDER_URL: baseUrls.SERVICES_BASE_URL + '/oauthtokenprovider/',
  TWITTER_SERVICE_URL: baseUrls.SERVICES_BASE_URL + '/twitter/',
  GOOGLE_SERVICE_URL: baseUrls.SERVICES_BASE_URL + '/google/',
  DATA_SERVICE_URL: baseUrls.SERVICES_BASE_URL + '/data/',
  POWERBI_SERVICE_URL: baseUrls.SERVICES_BASE_URL + '/data/ms-pbi/',
  MICROSOFT_OUTLOOK_SERVICE_URL: baseUrls.SERVICES_BASE_URL + '/data/ms-graph/calendar/',
  MICROSOFT_ONEDRIVE_SERVICE_URL: baseUrls.SERVICES_BASE_URL + '/data/ms-graph/onedrive/',
  HTML_TEMPLATE_URL: baseUrls.TEMPLATES_BASE_URL + '/src/template.html',
  SSO_LOGIN_URL: urlGetters.CORE_BASE_URL + '/sso/login',
  SPRING_ENDPOINT_URL: urlGetters.CORE_BASE_URL + '/api/core',
};
