export const baseUrls = {
  CORE_BASE_URL: 'https://rvacore-test.appspot.com',
  STORE_BASE_URL: 'https://store-dot-rvacore-test.appspot.com',
  STORAGE_BASE_URL: 'https://storage-dot-rvacore-test.appspot.com',
  VIEWER_BASE_URL: 'https://widgets.risevision.com/viewer-stage-0',
  TEMPLATES_BASE_URL: 'https://widgets.risevision.com/staging/templates/PRODUCT_CODE',
  SERVICES_BASE_URL: 'https://services-stage.risevision.com',
  SERVICES2_BASE_DOMAIN: 'services2-stage.risevision.com',
};

export const environment = {
  production: false,
  canvaApiKey: 'EwLWFws4Qjpa-n_2ZJgBMQbz',
  TAG_MANAGER_CONTAINER_ID: 'GTM-MMTK3JH',
  TAG_MANAGER_AUTH: 'Ry3lxk_Xrlx2qhbXmLA-Pg',
  TAG_MANAGER_ENV: 'env-254',
  ENV_NAME: 'STAGE',
  HUBSPOT_ACCOUNT: '2939619',
  STRIPE_API_KEY: 'pk_test_GrMIAHSoqhaik4tcHepsxjOR',
  PIXABAY_API_KEY: '42941787-c47b7eb6f834a8cdbc497637d'
};
